<template>
  <div>
  </div>
</template>
<script>
export default {
  mounted() {
    this.$http.post("/Base_Manage/Base_VIPUserInfo/BindingVIPUserWxUnionid?AppType=zytsaas_pc&Code=" + this.$route.query.code)
        .then(async (res) => {
          if (res.Success) {
            if (res.Data.code === '200') {
              this.$message.success(res.Data.msg)
            }
            if (res.Data.code === '300' || res.Data.code === '500') {
              this.$message.error(res.Data.msg)
            }
            await this.$store.dispatch("getUserInfo");
            //设置Vuex登录标志为true，默认userLogin为false
            await this.$store.dispatch("setUser", true);
          } else {
            this.$message.error("微信授权获取失败,请重新尝试");
          }
          this.$router.replace({path: "/My?type=安全设置"});
        });
  }
}
</script>
<style lang="scss" scoped></style>